<template>
  <cz-dialog
    :width="500"
    v-on="$listeners"
    v-bind="$attrs"
    :title="$t('customer.vendorSubmissions.exportSubmissions')"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form class="pa-4" @submit.prevent="onExport" :disabled="loading">
        <cz-form-field required :label="$t('common.fromDate')">
          <cz-autocomplete
            v-model="period"
            :items="preriodItems"
            item-text="text"
            item-value="value"
            :placeholder="$t('common.selectValue')"
            clearable
            hide-details
            return-object
          />
        </cz-form-field>
        <template v-if="isRangeSelected">
          <v-divider class="my-4" />
          <cz-form-field required :label="$t('common.fromDate')">
            <validation-provider
              rules="required"
              :name="$t('common.fromDate')"
              v-slot="{ errors }"
            >
              <cz-new-date-picker
                v-model="form.fromDate"
                dense
                :error-messages="errors"
                open-menu-on-click
              />
            </validation-provider>
          </cz-form-field>

          <cz-form-field required :label="$t('common.toDate')">
            <validation-provider
              rules="required"
              :name="$t('common.toDate')"
              v-slot="{ errors }"
            >
              <cz-new-date-picker
                v-model="form.toDate"
                :error-messages="errors"
                dense
                :min-date="form.fromDate"
                :disabled="!form.fromDate"
                open-menu-on-click
              />
            </validation-provider>
          </cz-form-field>
        </template>

        <cz-button
          color="info"
          type="submit"
          large
          :title="$t('common.download')"
          :icon-src="mdiDownloadCircleOutline"
          :icon-size="22"
          :disabled="invalid || !period"
          class="mt-6"
          :loading="loading"
          :min-width="120"
        />
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzFormField,
  CzButton,
  CzNewDatePicker,
  CzAutocomplete
} from '@/components';
import { parse } from 'date-fns';
import { mdiDownloadCircleOutline } from '@mdi/js';
import { getYear, startOfYear, endOfYear } from 'date-fns';
export default {
  name: 'ExportSubmissionsDialog',
  components: {
    CzDialog,
    CzFormField,
    CzButton,
    CzNewDatePicker,
    CzAutocomplete
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isRangeSelected() {
      return this.period?.value === 'range';
    }
  },
  created() {
    if (this.filteredInvitationTypes?.length === 1) {
      this.form.role = this.filteredInvitationTypes[0].value;
      this.invitationRoleDisabled = false;
    }
  },
  data() {
    return {
      mdiDownloadCircleOutline,
      form: {
        fromDate: null,
        toDate: null
      },
      period: null,
      preriodItems: [
        {
          value: 'current-year',
          text: this.$t('common.currentYear', [getYear(new Date())]),
          year: getYear(new Date())
        },
        {
          value: 'last-year',
          text: this.$t('common.lastYear', [getYear(new Date()) - 1]),
          year: getYear(new Date()) - 1
        },
        {
          value: 'range',
          text: this.$t('common.dateRange')
        }
      ]
    };
  },
  methods: {
    onExport() {
      let fromDate = null;
      let toDate = null;

      if (this.period?.year) {
        fromDate = startOfYear(new Date(this.period.year, 0));
        toDate = endOfYear(new Date(this.period.year, 0));
      } else {
        fromDate = parse(this.form.fromDate, 'yyyy-MM-dd', new Date());
        toDate = parse(this.form.toDate, 'yyyy-MM-dd', new Date());
      }

      this.$emit('on-export', {
        fromDate,
        toDate
      });
    }
  }
};
</script>

<style></style>
